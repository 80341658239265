import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css']
})
export class DebugComponent implements OnInit {

  constructor(
    public store: Store<any>,
  ) { }

  ngOnInit() {

  }

  sendTransaction() {

    // dispatch action for send transaction
    this.store.dispatch({
      type: 'TEZOS_SEND_TRANSACTION'
    });

  }
}
