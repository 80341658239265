import { environment } from '../environments/environment';

const initialState = {
    user: {
        uid: null,
        email: null,
        displayName: null,
    },
    tezos: {
        // import node settings
        node: environment.tezos.nodes[environment.tezos.default],
        contract: environment.tezos.contract,
    },
    progressbar: {
        isVisible: false,
        counter: 0,
    },
    sidenav: {
        isVisible: true,
        color: 'white',
        toggleButton: {
            isVisible: true,
        },
    },
    toolbar: {
        isVisible: true,
        color: 'white',
    },
    menu: {
        isCatalog: false,
        isAudit: true,
        isTransactions: false,
        isAdmin: false,
    }
};

export function reducer(state = initialState, action) {
    switch (action.type) {

        case 'WALLET_CHANGE': {
            switch (action.payload.role) {
                case 'auditor':
                    return {
                        ...state,
                        menu: {
                            isCatalog: false,
                            isAudit: true,
                            isTransactions: false,
                            isAdmin: false,
                        }
                    };
                case 'admin':
                    return {
                        ...state,
                        menu: {
                            isCatalog: false,
                            isAudit: false,
                            isTransactions: false,
                            isAdmin: true,
                        }
                    };
                default:
                    return {
                        ...state,
                        menu: {
                            isCatalog: true,
                            isAudit: false,
                            isTransactions: true,
                            isAdmin: false,
                        }
                    };
            }
        }


        case 'WALLET_BUY_DATA':
        case 'WALLET_REQUEST_PUBLICATION':
        case 'WALLET_CREATE_DATA':
        case 'WALLET_CREATE_META_DATA':
        case 'WALLET_ALLOW_PUBLICATION': {
            return {
                ...state,
                progressbar: {
                    isVisible: state.progressbar.counter >= 0 ? true : false,
                    counter: state.progressbar.counter + 1
                }
            }
        }

        case 'WALLET_REQUEST_PUBLICATION_SUCCESS':
        case 'WALLET_ALLOW_PUBLICATION_SUCCESS':
        case 'WALLET_ALLOW_PUBLICATION_ERROR':
        case 'WALLET_REQUEST_PUBLICATION_ERROR':
        case 'WALLET_CREATE_DATA_SUCCESS':
        case 'WALLET_CREATE_META_DATA_SUCCESS':
        case 'WALLET_CREATE_DATA_ERROR':
        case 'WALLET_CREATE_META_DATA_ERROR':
        case 'WALLET_BUY_DATA_SUCCESS':
        case 'WALLET_BUY_DATA_ERROR': {
            return {
                ...state,
                progressbar: {
                    counter: state.progressbar.counter - 1,
                    isVisible: state.progressbar.counter === 1 ? false : true,
                }
            }
        }

        default:
            return state;
    }
}