import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  public audit
  public auditDataSource
  constructor(
    public store: Store<any>,
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {

    // get audit data from smart contrat
    this.store.dispatch({
      type: 'AUDIT_LOAD',
    });

    // subscribe to app state
    this.store.select('audit')
      .subscribe(data => {

        this.audit = data.ids
          .filter(id => data.entities[id].params )
          .map(id => ({ id, ...data.entities[id] }))
          .sort((a, b) => b.timestamp - a.timestamp)

        this.auditDataSource = new MatTableDataSource<any>(this.audit);
        this.auditDataSource.paginator = this.paginator;

      })

  }

}
