import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  public wallet

  public wallets

  constructor(
    public store: Store<any>,
  ) { }

  ngOnInit() {
    // subscribe to app state
    this.store.select('wallet')
      .subscribe(data => {

        this.wallets = data.ids.map(id => ({ id, ...data.entities[id] }));
        this.wallet = data.selected;

      });
  }

  walletChange(wallet) {

    this.store.dispatch({
      type: 'WALLET_CHANGE',
      payload: wallet
    });

  }

}
