export const environment = {
  production: false,
  tezos: {
    nodes: {
      main: {
        display: 'Mainnet',
        name: 'main',
        url: 'https://mainnet.simplestaking.com:3000',
        tzstats: {
          url: 'https://tzstats.com/account/',
          api: 'https://api.tzstats.com/',
        },
      },
      babylon: {
        display: 'Babylon',
        name: 'babylon',
        url: 'https://alphanet.simplestaking.com:3000',
        tzstats: {
          url: 'https://cors-anywhere.herokuapp.com/https://babylonnet.tzstats.com/account/',
          api: 'https://cors-anywhere.herokuapp.com/https://api.babylonnet.tzstats.com',
        },
      },
      local: {
        display: 'Babylon',
        name: 'babylon',
        url: 'https://cors-anywhere.herokuapp.com/46.101.160.245:8732',
        tzstats: {
          url: 'https://babylonnet.tzstats.com/account/',
          api: 'https://api.babylonnet.tzstats.com',
        },
      },
      zero: {
        display: 'Zeronet',
        name: 'zero',
        url: 'https://zeronet.simplestaking.com:3000',
        tzstats: {
          url: 'https://zeronet.tzstats.com/account/',
          api: 'https://api.zeronet.tzstats.com/',
        },
      },
    },
    contract: {
      catalog: {
        // address: 'KT1Nab4p6rGSAsWjgE3N41kR1yVRGtvcRphj'
        address: 'KT1MQh4zB32igNDkteq98YJd4jrv9zy5bhyC',
      }
    },
    default: "babylon",
  }
}

