
import * as moment from 'moment/moment';

const initialState = {
    ids: [],
    entities: {},
    page: 0,
    itemsPerPage: 10,
    itemsTotalCount: 0
}


export function reducer(state = initialState, action) {
    switch (action.type) {

        case 'AUDIT_LOAD_SUCCESS': {
            // debugger
            return {
                ...state,
                ids: [
                    ...action.payload.map(operations => operations[0])
                ],
                entities: action.payload.reduce((accumulator, operation) => {
                    // convert date time to moment
                    const momentDateTime = moment(operation[1])
                    return {
                        ...accumulator,
                        // hash,time,sender,has_data,parameters,status,height
                        [operation[0]]: {
                            hash: operation[0],
                            timestamp: operation[1],
                            time: momentDateTime.format('DD MMM YYYY, HH:mm'),
                            sender: operation[2],
                            has_data: operation[3],
                            params: getEntryPoint(operation[4]),
                            data_id: getDataId(operation[4]),
                            status: operation[5],
                            height: operation[6]
                        }
                    }
                }
                    , {}),

            };
        }

        default:
            return state;
    }
}

// convert hex to string
export function hex2string(input) {

    // if empty return
    if (!input) return '';

    var hex = input.toString();
    var str = '';
    for (var i = 0; (i < hex.length && hex.substr(i, 2) !== '00'); i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;

}

// convert params for entry point call
export function getEntryPoint(input) {

    if (!input) return '';

    const entryPointNameSize = parseInt(input.substr(4, 2), 16)
    const entryPointName = input.substr(6, entryPointNameSize * 2)

    return hex2string(entryPointName);
}

export function getDataId(input) {

    const entryPointName = getEntryPoint(input);
    const entryPointNameSize = entryPointName.length;

    switch (entryPointName) {

        case 'createData':
        case 'allowDataPublication': {

            const getDataIdPointer = 6 + (entryPointNameSize * 2) + 18;
            const getDataIdSize = parseInt(input.substr(getDataIdPointer, 2), 16);

            return hex2string(input.substr(getDataIdPointer, getDataIdSize * 2));

        }

        case 'createMetaData': {

            const getDataIdPointer = 6 + (entryPointNameSize * 2) + 18;
            const getDataIdSize = parseInt(input.substr(getDataIdPointer, 2), 16);

            // console.log(
            //     getDataIdPointer,
            //     getDataIdSize,
            //     hex2string(input.substr(getDataIdPointer, getDataIdSize * 2)),
            //     input.substr(getDataIdPointer, getDataIdSize * 2),
            //     input
            // );

            return hex2string(input.substr(getDataIdPointer, getDataIdSize * 2));

        }

        case 'buyData': {
            const getDataIdPointer = 6 + (entryPointNameSize * 2) + 24;
            const getDataIdSize = parseInt(input.substr(getDataIdPointer, 2), 16);
            return hex2string(input.substr(getDataIdPointer + 2, getDataIdSize * 2));
        }

        case 'requestDataPublication': {
            const getDataIdPointer = 6 + (entryPointNameSize * 2) + 22;
            const getDataIdSize = parseInt(input.substr(getDataIdPointer, 2), 16);
            return hex2string(input.substr(getDataIdPointer, getDataIdSize * 2));
        }

        default:
            return '';

    }
}