import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  public transactions
  public transactionsDataSource
  constructor(
    public store: Store<any>,
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {

    // get catalog data from smart contrat
    this.store.dispatch({
      type: 'TRANSACTIONS_LOAD',
    });

    // subscribe to app state
    this.store.select('transactions')
      .subscribe(data => {

        this.transactions = data.ids.map(id => ({ id, ...data.entities[id] }))

        this.transactionsDataSource = new MatTableDataSource<any>(this.transactions);
        this.transactionsDataSource.paginator = this.paginator;

      })

  }

}
