
const initialState = {
    ids: [],
    entities: {},
    page: 0,
    itemsPerPage: 10,
    itemsTotalCount: 0,
    actions: {
        isRequestPublish: false,
        isPublish: false,
        isBuy: false,
    }
}


export function reducer(state = initialState, action) {
    switch (action.type) {

        case 'CATALOG_LOAD_SUCCESS': {
            return {
                ...state,
                ids: [
                    ...action.payload.map(catalog => catalog[0])
                ],
                entities: action.payload.reduce((accumulator, catalog) => {
                    return {
                        ...accumulator,
                        [catalog[0]]: {
                            data_creator: catalog[1].args[0].args[0].args[0].bytes,
                            data_id: catalog[1].args[0].args[0].args[1].string,
                            data_publisher: catalog[1].args[0].args[1].args[0].prim,
                            is_data_public: catalog[1].args[0].args[1].args[1].string,
                            meta_data_id: catalog[1].args[1].args[1].prim !== "Some" ?
                                catalog[1].args[1].args[1].prim :
                                catalog[1].args[1].args[1].args[0].string,
                            keywords: catalog[1].args[1].args[0].string,
                        }
                    }
                }
                    , {}),
            };
        }



        case 'WALLET_CHANGE': {
            switch (action.payload.role) {
                case 'customer':
                    return {
                        ...state,
                        actions: {
                            isRequestPublish: false,
                            isPublish: false,
                            isBuy: true,
                            isCreateData: false,
                            isCreateMetaData: false,
                        }
                    };
                case 'creator':
                    return {
                        ...state,
                        actions: {
                            isRequestPublish: true,
                            isPublish: false,
                            isBuy: false,
                            isCreateData: true,
                            isCreateMetaData: false,
                        }
                    };
                case 'meta_creator':
                    return {
                        ...state,
                        actions: {
                            isRequestPublish: true,
                            isPublish: false,
                            isBuy: false,
                            isCreateData: false,
                            isCreateMetaData: true,
                        }
                    };
                case 'publisher':
                    return {
                        ...state,
                        actions: {
                            isRequestPublish: false,
                            isPublish: true,
                            isBuy: false,
                            isCreateData: false,
                            isCreateMetaData: false,
                        }
                    };
                case 'admin':
                case 'auditor':
                    return {
                        ...state,
                        actions: {
                            isRequestPublish: false,
                            isPublish: false,
                            isBuy: false,
                            isCreateData: false,
                            isCreateMetaData: false,
                        }
                    };
                default:
                    return {
                        ...state,
                        actions: {
                            isRequestPublish: false,
                            isPublish: false,
                            isBuy: false,
                            isCreateData: false,
                            isCreateMetaData: false,
                        }
                    };
            }
        }

        default:
            return state;
    }
}