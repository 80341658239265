import { Injectable, InjectionToken, Optional, Inject, NgZone } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { withLatestFrom, flatMap, map, catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { enterZone } from '../shared/utils/rxjs/operators';

import { initializeWallet, transaction, confirmOperation, originateContract, getWallet, Config } from 'tezos-wallet'

@Injectable()
export class CatalogEffects {


    // get catalog data from users big map
    @Effect()
    CatalogLoadWallet$ = this.actions$.pipe(
        ofType('CATALOG_LOAD'),

        // get state from store
        withLatestFrom(this.store, (action, state: any) => ({ action, state })),

        flatMap(({ action, state }) =>
            // this.http.get(state.app.tezos.node.url + '/chains/main/blocks/head/context/raw/json/big_maps/index/628/contents/?depth=5')
            this.http.get(state.app.tezos.node.url + '/chains/main/blocks/head/context/raw/json/big_maps/index/653/contents/?depth=5')
        ),

        map((response) => ({ type: 'CATALOG_LOAD_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'CATALOG_LOAD_ERROR',
                payload: error.message,
            });
            return caught;
        }),
    );


    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private router: Router,
        private zone: NgZone,
        private http: HttpClient,
    ) { }

}