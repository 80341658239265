import { Injectable, InjectionToken, Optional, Inject, NgZone } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { withLatestFrom, flatMap, map, catchError, tap } from 'rxjs/operators';

import { enterZone } from '../shared/utils/rxjs/operators';

import { initializeWallet, transaction, confirmOperation, originateContract, getWallet, Config } from 'tezos-wallet'

const wallet: Config = {
    secretKey: 'edsk3hEtniBGLP2wqYnc1Lix1hhV74eEenRBTfxjHTRHP59R7BapMW',
    publicKey: 'edpkth42B7j7rvLeZWmufj28a7sEdbMBb9y16qgQmGHYGX4hPis9V4',
    publicKeyHash: 'tz1WCojrEZWrjenejUZmG8QNsMtKPELx2TFA',
    node: {
        name: 'babylon',
        display: 'Babylon',
        url: 'https://alphanet.simplestaking.com:3000',
        tzstats: {
            url: 'http://babylonnet.tzstats.com/account/',
        }
    },
    type: 'web'
}
@Injectable()
export class DebugEffects {

    @Effect()
    TezosSendTransaction$: Observable<any> = this.actions$.pipe(
        ofType('TEZOS_SEND_TRANSACTION'),
        withLatestFrom(this.store, (action, state) => ({ action, state })),

        flatMap(({ action, state }) => of([]).pipe(


            // wait until sodium is ready
            initializeWallet(stateWallet => ({
                secretKey: wallet.secretKey,
                publicKey: wallet.publicKey,
                // for smart contract use manager address
                publicKeyHash: wallet.publicKeyHash,
                // set tezos node
                node: wallet.node,


                type: wallet.type,
            })),

            // tz-stats
            // https://api.babylonnet.tzstats.com/tables/op?columns=row_id,time,status,hash,sender,big_map_diff&receiver=KT1Ueoy8N2k45A5JVS5SSRNAA6oP4hweHR3o

            // contract
            // https://alphanet.simplestaking.com:3000/chains/main/blocks/head/context/contracts/KT1Ueoy8N2k45A5JVS5SSRNAA6oP4hweHR3o

           
            // transfer tokens
            transaction(stateWallet => ({
                to: state.app.tezos.contract.catalog.address,
                amount: '0',
                fee: '0.1',

                parameters: {
                    "entrypoint": "setRole",
                    "value": {
                        "prim": "Pair",
                        "args":
                            [{
                                "prim": "Pair",
                                "args": [{
                                    "prim": "Pair",
                                    "args":
                                        [{ "prim": "False" },
                                        { "prim": "False" }]
                                },
                                {
                                    "prim": "Pair",
                                    "args":
                                        [{ "prim": "False" },
                                        { "prim": "False" }]
                                }]
                            },
                            { "string": "tz1MGtVUsRN2duS21pLgSUuAhuW1hhQmLXKP" }]
                    }
                },

            })),

            // wait until operation is confirmed & moved from mempool to head
            confirmOperation(stateWallet => ({
                injectionOperation: stateWallet.injectionOperation,
            })),

            // enter back into zone.js so change detection works
            enterZone(this.zone),

        )),

        map(action => ({ type: 'TEZOS_SEND_TRANSACTION_SUCCESS', payload: action })),
        // dispatch error action
        catchError((error, caught) => {
            console.error(error)
            this.store.dispatch({
                type: 'TEZOS_SEND_TRANSACTION_ERROR', payload: error.response,
            });
            return caught;
        }))

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private router: Router,
        private zone: NgZone,
    ) { }

}