import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing'

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, metaReducers } from './app.reducers';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

// import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSidenavModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatCardModule,
  // MatCheckboxModule,
  // MatChipsModule,
  // MatDatepickerModule,
  MatDialogModule,
  // MatExpansionModule,
  // MatGridListModule,
  MatMenuModule,
  MatPaginatorModule,
  // MatRadioModule,
  // MatSliderModule,
  // MatSlideToggleModule,
  // MatSortModule,
  // MatStepperModule,
} from '@angular/material';

import { DebugComponent } from './debug/debug.component';


import { AllEffects } from './app.effects'
import { AuditEffects } from './audit/audit.effects'
import { AdminEffects } from './admin/admin.effects'
import { CatalogEffects } from './catalog/catalog.effects'
import { TransactionsEffects } from './transactions/transactions.effects'
import { WalletEffects } from './wallet/wallet.effects'

import { DebugEffects } from './debug/debug.effects';
import { DialogEffects } from './shared/dialog/dialog.effects'

import { DialogComponent } from './shared/dialog/dialog.component';
import { SvgIconComponent } from './shared/svg-icon/svg-icon.component';
import { AdminComponent } from './admin/admin.component';
import { CatalogComponent } from './catalog/catalog.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AuditComponent } from './audit/audit.component';
import { WalletComponent } from './wallet/wallet.component';
import { CreateComponent } from './create/create.component'



@NgModule({
  declarations: [
    AppComponent,
    DebugComponent,
    DialogComponent,
    SvgIconComponent,
    AdminComponent,
    CatalogComponent,
    TransactionsComponent,
    AuditComponent,
    WalletComponent,
    CreateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,

    RouterModule.forRoot(AppRouting, {
      //useHash:true
      preloadingStrategy: PreloadAllModules
    }),

    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({
    }),

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),

    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],

    // Set side effects
    EffectsModule.forRoot([
      AllEffects,
      AuditEffects,
      AdminEffects,
      CatalogEffects,
      TransactionsEffects,
      WalletEffects,
      DialogEffects,
      DebugEffects
    ]),

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    MatTableModule,
    // MatDatepickerModule,
    MatDialogModule,
    // MatExpansionModule,
    MatFormFieldModule,
    // MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    // MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    // MatSlideToggleModule,
    // MatSliderModule,
    MatSnackBarModule,
    // MatSortModule,
    // MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogComponent
  ]
})
export class AppModule { }
