
const initialState = {
    ids: [],
    entities: {},
    page: 0,
    itemsPerPage: 10,
    itemsTotalCount: 0
}


export function reducer(state = initialState, action) {
    switch (action.type) {

        case 'TRANSACTIONS_LOAD_SUCCESS': {

            return {
                ids: [
                    ...action.payload.map(transactions => transactions[0])
                ],
                entities: action.payload.reduce((accumulator, transaction) => {

                    return {
                        ...accumulator,
                        [transaction[0]]: {

                            transaction_id: transaction[1].args[0].args[0].bytes,
                            data_id:        transaction[1].args[0].args[1].string,

                            customer:       transaction[1].args[1].args[0].string,
                            signature:      transaction[1].args[1].args[1].string,

                        }
                    }

                }, {}),
            };
        }

        default:
            return state;
    }
}