
const initialState = {
    ids: [
        'tz1VXDYKscRsAWm2ESVFQa3dzdWdapbSbDcm',
        'tz1dWBTTN38GCYEjTajVa5oTayw6H6d42uJX',
        'tz1epncWCL1vGFz1UgyTZHdE4v1u417ETJa9',
        'tz1igoyxEgLgTPVxU3JsBwgn4bcLSXTXuHdw',
        'tz1PkNVy8aM4TSciWZg99KexYjX622mXnx8j',
        'tz1WCojrEZWrjenejUZmG8QNsMtKPELx2TFA',
    ],
    entities: {
        tz1WCojrEZWrjenejUZmG8QNsMtKPELx2TFA: {
            name: 'Admin',
            role: 'admin',
            secretKey: 'edsk3hEtniBGLP2wqYnc1Lix1hhV74eEenRBTfxjHTRHP59R7BapMW',
            publicKey: 'edpkth42B7j7rvLeZWmufj28a7sEdbMBb9y16qgQmGHYGX4hPis9V4',
            publicKeyHash: 'tz1WCojrEZWrjenejUZmG8QNsMtKPELx2TFA',
        },
        tz1PkNVy8aM4TSciWZg99KexYjX622mXnx8j: {
            name: 'Auditor',
            role: 'auditor',
            secretKey: 'edsk3Xz7H9wY7fLX53VQ4fk4icwKtsSeAjoriKD9JHYSDtYvg8zjdu',
            publicKey: 'edpktigW7ixPdE2hV5ZtHkg2uenHgfqVTfjvQRwAkqo5cc2aYSKctn',
            publicKeyHash: 'tz1PkNVy8aM4TSciWZg99KexYjX622mXnx8j',
        },
        tz1VXDYKscRsAWm2ESVFQa3dzdWdapbSbDcm: {
            name: 'Creator',
            role: 'creator',
            secretKey: 'edsk4Q6r9wnDcwnnonojc1HYhNRfpk2MpsRF5VuJc5VGhs9K1XyXr4',
            publicKey: 'edpkvPUCDv4DYC37FvpXvc1ZfPYNVhTzU2xiqUTKDPDaQFmPw5UsA8',
            publicKeyHash: 'tz1VXDYKscRsAWm2ESVFQa3dzdWdapbSbDcm',
        },
        tz1dWBTTN38GCYEjTajVa5oTayw6H6d42uJX: {
            name: 'Meta Creator',
            role: 'meta_creator',
            secretKey: 'edsk48tjtJ1kv7kiyBJxJ1rYqFz1njNVa1G8kneTEonSbD3M4tJTaR',
            publicKey: 'edpku3TXHsE9kZdphTdYz6pSUQvkzAeUXgYmWGVs4AzYBdT12hD3CP',
            publicKeyHash: 'tz1dWBTTN38GCYEjTajVa5oTayw6H6d42uJX'
        },
        tz1epncWCL1vGFz1UgyTZHdE4v1u417ETJa9: {
            name: 'Publisher',
            role: 'publisher',
            secretKey: 'edsk3neLbXv2n2L4V3zR24cgWCFEWqzwPY5cp7K5N96Jwuq7ov6qfp',
            publicKey: 'edpku4h8cY57pWdxBWuoDKBRQg6MjSnV2oNAPyVqi3x9rSoUKNf5LE',
            publicKeyHash: 'tz1epncWCL1vGFz1UgyTZHdE4v1u417ETJa9'
        },
        tz1igoyxEgLgTPVxU3JsBwgn4bcLSXTXuHdw: {
            name: 'Customer',
            role: 'customer',
            secretKey: 'edsk2p9bj1F4aKYSi2cec7xKNS2SoQuwDn7rgX6rBzCqvrRH5BdhZZ',
            publicKey: 'edpkvLDoKFPXD57YPSnYp5HyPmibVFqYqvQc7hFFYsJfNYBEQLLNxY',
            publicKeyHash: 'tz1igoyxEgLgTPVxU3JsBwgn4bcLSXTXuHdw'
        }
    },
    selected: {
        name: 'Auditor',
        role: 'auditor',
        secretKey: 'edsk3Xz7H9wY7fLX53VQ4fk4icwKtsSeAjoriKD9JHYSDtYvg8zjdu',
        publicKey: 'edpktigW7ixPdE2hV5ZtHkg2uenHgfqVTfjvQRwAkqo5cc2aYSKctn',
        publicKeyHash: 'tz1PkNVy8aM4TSciWZg99KexYjX622mXnx8j',
    },
}


export function reducer(state = initialState, action) {
    switch (action.type) {

        case 'WALLET_CHANGE': {
            return {
                ...state,
                selected: state.entities[action.payload.publicKeyHash]
            };
        }

        default:
            return state;
    }
}