import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store'
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public app
  public wallet

  constructor(
    public store: Store<any>,
    public router: Router,
  ) { }

  ngOnInit() {

    // subscribe to app state
    this.store.select('app')
      .subscribe(data => {
        this.app = data
      })

    this.store.dispatch({
      type: 'WALLET_CHANGE',
      payload: {
        // name: 'Publisher',
        // role: 'publisher',
        // secretKey: 'edsk3neLbXv2n2L4V3zR24cgWCFEWqzwPY5cp7K5N96Jwuq7ov6qfp',
        // publicKey: 'edpku4h8cY57pWdxBWuoDKBRQg6MjSnV2oNAPyVqi3x9rSoUKNf5LE',
        // publicKeyHash: 'tz1epncWCL1vGFz1UgyTZHdE4v1u417ETJa9'

        name: 'Creator',
        role: 'creator',
        secretKey: 'edsk4Q6r9wnDcwnnonojc1HYhNRfpk2MpsRF5VuJc5VGhs9K1XyXr4',
        publicKey: 'edpkvPUCDv4DYC37FvpXvc1ZfPYNVhTzU2xiqUTKDPDaQFmPw5UsA8',
        publicKeyHash: 'tz1VXDYKscRsAWm2ESVFQa3dzdWdapbSbDcm',

      }
    });


  }


}
