import { Injectable, InjectionToken, Optional, Inject, NgZone } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { withLatestFrom, flatMap, map, catchError, tap } from 'rxjs/operators';

import { enterZone } from '../shared/utils/rxjs/operators';

import { initializeWallet, transaction, confirmOperation, originateContract, getWallet, Config } from 'tezos-wallet'

@Injectable()
export class WalletEffects {


    // redirect
    @Effect()
    WalletRedirect$ = this.actions$.pipe(
        ofType('WALLET_CREATE_META_DATA_SUCCESS', 'WALLET_CREATE_DATA_SUCCESS',
            'WALLET_CREATE_DATA_ERROR', 'WALLET_CREATE_META_DATA_ERROR',
            'WALLET_REQUEST_PUBLICATION_SUCCESS',
            'WALLET_ALLOW_PUBLICATION_SUCCESS',
            'WALLET_ALLOW_PUBLICATION_ERROR',
            'WALLET_REQUEST_PUBLICATION_ERROR'),
        // redirect
        tap(() => {
            return this.router.navigate(['/catalog']);
        }),
        map(() => ({ type: 'CATALOG_LOAD' })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'REDIRECT_ERROR',
                payload: error.message,
            });
            return caught;
        })
    )

    // redirect
    @Effect()
    WalletBuyDataRedirect$ = this.actions$.pipe(
        ofType('WALLET_BUY_DATA_ERROR', 'WALLET_BUY_DATA_SUCCESS'),
        // redirect
        tap(({ action, state }) => {
            return this.router.navigate(['/transactions']);
        }),
        map(() => ({ type: 'TRANSACTIONS_LOAD' })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'REDIRECT_ERROR',
                payload: error.message,
            });
            return caught;
        })
    )

    // change user and redirect
    @Effect()
    WalletChange$ = this.actions$.pipe(
        ofType('WALLET_CHANGE'),

        // get state from store
        withLatestFrom(this.store, (action: any, state: any) => ({ action, state })),

        // redirect
        tap(({ action, state }) => {
            switch (action.payload.role) {
                case 'admin':
                    return this.router.navigate(['/admin']);
                case 'auditor':
                    return this.router.navigate(['/audit']);
                case 'creator':
                case 'meta_creator':
                case 'publisher':
                case 'customer':
                    return this.router.navigate(['/catalog']);
                default:
                    return this.router.navigate(['/catalog']);
            }
        }),

        map((response) => ({ type: 'WALLET_LOAD_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'WALLET_LOAD_ERROR',
                payload: error.message,
            });
            return caught;
        }),
    );


    // request Publication
    @Effect()
    WalletRequestPublication$ = this.actions$.pipe(
        ofType('WALLET_REQUEST_PUBLICATION'),

        // get state from store
        withLatestFrom(this.store, (action: any, state: any) => ({ action, state })),

        flatMap(({ action, state }) => of([]).pipe(

            tap(() => console.log('[effects][WALLET_REQUEST_PUBLICATION]', action, state)),

            // wait until sodium is ready
            initializeWallet(stateWallet => ({
                secretKey: state.wallet.selected.secretKey,
                publicKey: state.wallet.selected.publicKey,
                // for smart contract use manager address
                publicKeyHash: state.wallet.selected.publicKeyHash,
                // set tezos node
                node: state.app.tezos.node,
                type: 'web',
            })),

            // transfer tokens
            transaction(stateWallet => ({
                to: state.app.tezos.contract.catalog.address,
                amount: '0',
                fee: '0.1',

                parameters: {
                    "entrypoint": "requestDataPublication",
                    "value":
                    {
                        "prim": "Pair",
                        "args":
                            [{ "string": action.payload.data_id },
                            // default publisher address
                            { "string": "tz1epncWCL1vGFz1UgyTZHdE4v1u417ETJa9" }
                                // { "string": "tz1WCojrEZWrjenejUZmG8QNsMtKPELx2TFA" }
                            ]
                    }
                },

            })),

            // wait until operation is confirmed & moved from mempool to head
            confirmOperation(stateWallet => ({
                injectionOperation: stateWallet.injectionOperation,
            })),

            // enter back into zone.js so change detection works
            enterZone(this.zone),

        )),

        map((response) => ({ type: 'WALLET_REQUEST_PUBLICATION_SUCCESS', payload: response })),
        catchError((error, caught) => {
            this.store.dispatch({
                type: 'WALLET_REQUEST_PUBLICATION_ERROR',
                payload: error.response,
            });
            return caught;
        }),
    );

    // get transactions data from users big map
    @Effect()
    WalletAllowPublication$ = this.actions$.pipe(
        ofType('WALLET_ALLOW_PUBLICATION'),

        // get state from store
        withLatestFrom(this.store, (action: any, state: any) => ({ action, state })),

        flatMap(({ action, state }) => of([]).pipe(

            tap(() => console.log('[effects][WALLET_REQUEST_PUBLICATION]', action, state)),

            // wait until sodium is ready
            initializeWallet(stateWallet => ({
                secretKey: state.wallet.selected.secretKey,
                publicKey: state.wallet.selected.publicKey,
                // for smart contract use manager address
                publicKeyHash: state.wallet.selected.publicKeyHash,
                // set tezos node
                node: state.app.tezos.node,
                type: 'web',
            })),

            // transfer tokens
            transaction(stateWallet => ({
                to: state.app.tezos.contract.catalog.address,
                amount: '0',
                fee: '0.1',
                // allowDataPublication
                parameters:
                {
                    "entrypoint": "allowDataPublication",
                    "value": { "string": action.payload.data_id }
                },

            })),

            // wait until operation is confirmed & moved from mempool to head
            confirmOperation(stateWallet => ({
                injectionOperation: stateWallet.injectionOperation,
            })),

            // enter back into zone.js so change detection works
            enterZone(this.zone),

        )),

        map((response) => ({ type: 'WALLET_ALLOW_PUBLICATION_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error)
            this.store.dispatch({
                type: 'WALLET_ALLOW_PUBLICATION_ERROR',
                payload: error.response,
            });
            return caught;
        }),
    );

    // buyData
    @Effect()
    WalletBuyData$ = this.actions$.pipe(
        ofType('WALLET_BUY_DATA'),

        // get state from store
        withLatestFrom(this.store, (action: any, state: any) => ({ action, state })),

        flatMap(({ action, state }) => of([]).pipe(

            tap(() => console.log('[effects][WALLET_BUY_DATA]', action, state)),

            // wait until sodium is ready
            initializeWallet(stateWallet => ({
                secretKey: state.wallet.selected.secretKey,
                publicKey: state.wallet.selected.publicKey,
                // for smart contract use manager address
                publicKeyHash: state.wallet.selected.publicKeyHash,
                // set tezos node
                node: state.app.tezos.node,
                type: 'web',
            })),

            // transfer tokens
            transaction(stateWallet => ({
                to: state.app.tezos.contract.catalog.address,
                amount: '0',
                fee: '0.1',

                // buyData
                parameters: {
                    "entrypoint": "buyData",
                    "value":
                    {
                        "prim": "Pair",
                        "args":
                            [{
                                "prim": "Pair",
                                "args":
                                    [{ "string": action.payload.data_id },
                                    { "string": Math.floor(Math.random() * 100000).toString() }]
                            },
                            { "string": Math.floor(Math.random() * 100000).toString() }]
                    }
                },

            })),

            // wait until operation is confirmed & moved from mempool to head
            confirmOperation(stateWallet => ({
                injectionOperation: stateWallet.injectionOperation,
            })),

            // enter back into zone.js so change detection works
            enterZone(this.zone),

        )),

        map((response) => ({ type: 'WALLET_BUY_DATA_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error)
            this.store.dispatch({
                type: 'WALLET_BUY_DATA_ERROR',
                payload: error.response,
            });
            return caught;
        }),
    );


    // create data
    @Effect()
    WalletCreateData$ = this.actions$.pipe(
        ofType('WALLET_CREATE_DATA'),

        // get state from store
        withLatestFrom(this.store, (action: any, state: any) => ({ action, state })),

        flatMap(({ action, state }) => of([]).pipe(

            tap(() => console.log('[effects][WALLET_CREATE_DATA]', action, state)),

            // wait until sodium is ready
            initializeWallet(stateWallet => ({
                secretKey: state.wallet.selected.secretKey,
                publicKey: state.wallet.selected.publicKey,
                // for smart contract use manager address
                publicKeyHash: state.wallet.selected.publicKeyHash,
                // set tezos node
                node: state.app.tezos.node,
                type: 'web',
            })),

            // transfer tokens
            transaction(stateWallet => ({
                to: state.app.tezos.contract.catalog.address,
                amount: '0',
                fee: '0.1',

                // create data
                parameters: {
                    "entrypoint": "createData",
                    "value":
                    {
                        "prim": "Pair",
                        "args":
                            [{ "string": action.payload.data_id }, { "string": action.payload.keywords }]
                    }
                },

            })),

            // wait until operation is confirmed & moved from mempool to head
            confirmOperation(stateWallet => ({
                injectionOperation: stateWallet.injectionOperation,
            })),

            // enter back into zone.js so change detection works
            enterZone(this.zone),

        )),

        map((response) => ({ type: 'WALLET_CREATE_DATA_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error)
            this.store.dispatch({
                type: 'WALLET_CREATE_DATA_ERROR',
                payload: error.response,
            });
            return caught;
        }),
    );

    // create data
    @Effect()
    WalletCreateMETAData$ = this.actions$.pipe(
        ofType('WALLET_CREATE_META_DATA'),

        // get state from store
        withLatestFrom(this.store, (action: any, state: any) => ({ action, state })),

        flatMap(({ action, state }) => of([]).pipe(

            tap(() => console.log('[effects][WALLET_CREATE_META_DATA]', action, state)),

            // wait until sodium is ready
            initializeWallet(stateWallet => ({
                secretKey: state.wallet.selected.secretKey,
                publicKey: state.wallet.selected.publicKey,
                // for smart contract use manager address
                publicKeyHash: state.wallet.selected.publicKeyHash,
                // set tezos node
                node: state.app.tezos.node,
                type: 'web',
            })),

            // transfer tokens
            transaction(stateWallet => ({
                to: state.app.tezos.contract.catalog.address,
                amount: '0',
                fee: '0.1',

                // createMetadata
                parameters: {
                    "entrypoint": "createMetadata",
                    "value":
                    {
                        "prim": "Pair",
                        "args":
                            [{
                                "prim": "Pair",
                                "args":
                                    [{ "string": action.payload.data_id },
                                    { "string": action.payload.keywords }]
                            },
                            { "string": action.payload.meta_data_id }]
                    }
                },

            })),

            // wait until operation is confirmed & moved from mempool to head
            confirmOperation(stateWallet => ({
                injectionOperation: stateWallet.injectionOperation,
            })),

            // enter back into zone.js so change detection works
            enterZone(this.zone),

        )),

        map((response) => ({ type: 'WALLET_CREATE_META_DATA_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error)
            this.store.dispatch({
                type: 'WALLET_CREATE_META_DATA_ERROR',
                payload: error.response,
            });
            return caught;
        }),
    );

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private router: Router,
        private http: HttpClient,
        private zone: NgZone,
    ) { }

}