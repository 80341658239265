import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

  public catalog
  public catalogDataSource
  public actions

  constructor(
    public store: Store<any>,
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {

    // get catalog data from smart contrat
    this.store.dispatch({
      type: 'CATALOG_LOAD',
    });

    // subscribe to app state
    this.store.select('catalog')
      .subscribe(data => {

        this.catalog = data.ids.map(id => ({ id, ...data.entities[id] }));

        this.catalogDataSource = new MatTableDataSource<any>(this.catalog);
        this.catalogDataSource.paginator = this.paginator;

        this.actions = data.actions;
      })

  }

  allowPublication(data_id) {

    console.log('[WALLET_ALLOW_PUBLICATION]', data_id)

    // allow publication
    this.store.dispatch({
      type: 'WALLET_ALLOW_PUBLICATION',
      payload: {
        data_id: data_id,
      }
    });

  }

  requestPublication(data_id) {

    console.log('[WALLET_REQUEST_PUBLICATION]', data_id);

    // request publication
    this.store.dispatch({
      type: 'WALLET_REQUEST_PUBLICATION',
      payload: {
        data_id: data_id,
      }
    });

  }

  buyData(data_id) {

    console.log('[WALLET_BUY_DATA]',data_id)

    // request buy data
    this.store.dispatch({
      type: 'WALLET_BUY_DATA',
      payload: {
        data_id: data_id,
      }
    });

  }

  createData() {

    console.log('[WALLET_CREATE_DATA]');

    // request create data
    this.store.dispatch({
      type: 'WALLET_CREATE_DATA',
    });

  }

}
