import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatPaginator, MatTableDataSource } from '@angular/material';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public users
  public usersDataSource
  constructor(
    public store: Store<any>,
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {

    // get users data from smart contrat
    this.store.dispatch({
      type: 'ADMIN_USERS_LOAD',
    })

    // subscribe to app state
    this.store.select('admin')
      .subscribe(data => {
        
        this.users = data.ids.map(id => ({ id, ...data.entities[id] }));

        this.usersDataSource = new MatTableDataSource<any>(this.users);
        this.usersDataSource.paginator = this.paginator;

      })

  }

  setUserRole() {

    this.store.dispatch({
      type: 'TEZOS_SEND_TRANSACTION',
    })

  }

}
