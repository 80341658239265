import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from "@angular/material";

import { Store } from '@ngrx/store'
import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  public dialog
  public destroy$ = new Subject<null>();

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    public store: Store<any>,
  ) { }

  ngOnInit() {

    // listen for redux data  
    this.store.select('dialog')
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        this.dialog = state;
      })

  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {

    this.store.dispatch({
      type: 'DIALOG_DESTROY',
    })

    // close all observables
    this.destroy$.next();
    this.destroy$.complete();

  }
}
