import { Injectable, NgZone } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { withLatestFrom, flatMap, map, tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AdminEffects {


    // get admin data from users big map
    @Effect()
    AdminUsersLoadWallet$ = this.actions$.pipe(
        ofType('ADMIN_USERS_LOAD','TEZOS_SEND_TRANSACTION_SUCCESS'),

        // get state from store
        withLatestFrom(this.store, (action, state: any) => ({ action, state })),

        flatMap(({ action, state }) =>
            // this.http.get(state.app.tezos.node.url + '/chains/main/blocks/head/context/raw/json/big_maps/index/630/contents/?depth=5')
            this.http.get(state.app.tezos.node.url + '/chains/main/blocks/head/context/raw/json/big_maps/index/655/contents/?depth=5')
        ),

        map((response) => ({ type: 'ADMIN_USERS_LOAD_SUCCESS', payload: response })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'ADMIN_USERS_LOAD_ERROR',
                payload: error.message,
            });
            return caught;
        }),
    );

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private http: HttpClient,
    ) { }

}