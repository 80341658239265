import { Routes } from '@angular/router'
import { RouterStateSnapshot, Params } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { AdminComponent } from './admin/admin.component';
import { AuditComponent } from './audit/audit.component';
import { CatalogComponent } from './catalog/catalog.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { CreateComponent } from './create/create.component';
import { DebugComponent } from './debug/debug.component';


export const AppRouting: Routes = [

    { path: 'catalog', component: CatalogComponent },
    { path: 'catalog/create', component: CreateComponent },
    { path: 'transactions', component: TransactionsComponent },
    { path: 'admin', component: AdminComponent },
    { path: 'audit', component: AuditComponent },

    { path: 'debug', component: DebugComponent },
    { path: '', redirectTo: '/catalog', pathMatch: 'full' },
    // { path: '**', component: PageNotFoundComponent }

];

export interface RouterStateUrl {
    url: string;
    queryParams: Params;
}

export class CustomRouterStateSerializer
    implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        const queryParams = routerState.root.queryParams;

        return { url, queryParams };
    }
}