import { Component, OnInit, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  public data_id;
  public meta_data_id;
  public keywords;

  public wallet;

  constructor(
    public store: Store<any>,
  ) { }

  ngOnInit() {

    // subscribe to app state
    this.store.select('wallet')
      .subscribe(data => {
        this.wallet = data;
      });

  }

  createData() {
    console.log('[createData]', this.data_id, this.meta_data_id, this.keywords);
    if (this.data_id) {
      this.store.dispatch({
        type: 'WALLET_CREATE_DATA',
        payload: {
          data_id: this.data_id,
          keywords: this.keywords,
          role: this.wallet.selected.role,
        }
      });
    }
  }

  createMetaData() {
    console.log('[createMetaData]', this.data_id, this.meta_data_id, this.keywords);
    if (this.data_id) {
      this.store.dispatch({
        type: 'WALLET_CREATE_META_DATA',
        payload: {
          data_id: this.data_id,
          meta_data_id: this.meta_data_id,
          keywords: this.keywords,
          role: this.wallet.selected.role,
        }
      });
    }
  }

}
