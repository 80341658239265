import {
    ActionReducerMap,
    ActionReducer,
    MetaReducer,
} from '@ngrx/store';

import { storeLogger } from 'ngrx-store-logger';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from './app.routing';

import { environment } from '../environments/environment';

//   add remote error loging
//   import * as LogRocket from 'logrocket';
//   import createNgrxMiddleware from 'logrocket-ngrx';

import * as fromApp from './app.reducer';
import * as fromAdmin from './admin/admin.reducer';
import * as fromAudit from './audit/audit.reducer';
import * as fromCatalog from './catalog/catalog.reducer';
import * as fromTransactions from './transactions/transactions.reducer';
import * as fromWallet from './wallet/wallet.reducer';

import * as fromDialog from './shared/dialog/dialog.reducer';

// state shape interface
export interface State {
    app: any;
    admin: any;
    audit: any;
    catalog: any;
    transactions: any;
    wallet: any;
    dialog: any;
    routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}

// state
export const reducers: ActionReducerMap<State> = {
    app: fromApp.reducer,
    admin: fromAdmin.reducer,
    audit: fromAudit.reducer,
    catalog: fromCatalog.reducer,
    transactions: fromTransactions.reducer,
    wallet: fromWallet.reducer,
    dialog: fromDialog.reducer,
    routerReducer: fromRouter.routerReducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): any {
    // default, no options
    return storeLogger()(reducer);
}

//const logrocketMiddleware = createNgrxMiddleware(LogRocket);

// init metareducer
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? []
    : [logger];