
import * as sodium from 'libsodium-wrappers';
import * as bs58check from 'bs58check';
import * as bip39 from 'bip39';
import { Buffer } from 'buffer';

const initialState = {
    ids: [],
    entities: {},
    page: 0,
    itemsPerPage: 10,
    itemsTotalCount: 0
}


export function reducer(state = initialState, action) {
    switch (action.type) {

        case 'ADMIN_USERS_LOAD_SUCCESS': {
            return {
                ids: [
                    ...action.payload.map(admin => admin[0])
                ],
                entities: action.payload.reduce((accumulator, admin) => {
                    const address = admin[1].args[0].args[0].args[0].bytes;

                    return {
                        ...accumulator,
                        // hash of tz address
                        [admin[0]]: {
                            address: bs58checkEncode(prefix.tz1, hexToArrayBuffer( address.substr(4) ) ),
                            data_creator: admin[1].args[0].args[0].args[1].prim === "True" ? true : false,
                            metadata_creator: admin[1].args[0].args[1].args[0].prim === "True" ? true : false,
                            publisher: admin[1].args[0].args[1].args[1].prim === "True" ? true : false,
                            customer: admin[1].args[1].prim === "True" ? true : false,
                        }
                    }
                }, {}),
            };
        }

        default:
            return state;
    }
}


/**
 * Prefix table
 */
export const prefix = {
    tz1: new Uint8Array([6, 161, 159]),
    tz2: new Uint8Array([6, 161, 161]),
    tz3: new Uint8Array([6, 161, 164]),
    KT1: new Uint8Array([2, 90, 121]),
    B: new Uint8Array([1, 52]),
    edpk: new Uint8Array([13, 15, 37, 217]),
    sppk: new Uint8Array([3, 254, 226, 86]),
    p2pk: new Uint8Array([3, 178, 139, 127]),
    edsk64: new Uint8Array([43, 246, 78, 7]),
    edsk32: new Uint8Array([13, 15, 58, 7]),
    edsig: new Uint8Array([9, 245, 205, 134, 18]),
    operation: new Uint8Array([5, 116]),
}


export function bs58checkEncode(prefix: Uint8Array, payload: Uint8Array) {
    const n = new Uint8Array(prefix.length + payload.length);
    n.set(prefix);
    n.set(payload, prefix.length);
    return bs58check.encode(Buffer.from(n));
}

export function hexToArrayBuffer(hex) {
    var view = new Uint8Array(hex.length / 2)
    for (var i = 0; i < hex.length; i += 2) {
        view[i / 2] = parseInt(hex.substring(i, i + 2), 16)
    }
    return view
}