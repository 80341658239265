import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { MatDialog } from "@angular/material";
import { DialogComponent } from '../../shared/dialog/dialog.component'

import { map, withLatestFrom, flatMap, catchError, onErrorResumeNext, tap } from 'rxjs/operators';


@Injectable()
export class DialogEffects {

    // trigger data load based on navigation change  
    @Effect()
    DialogWarning$ = this.actions$.pipe(
        ofType('TEZOS_SEND_TRANSACTION_ERROR', 'WALLET_REQUEST_PUBLICATION_ERROR', 'WALLET_ALLOW_PUBLICATION_ERROR', 'WALLET_BUY_DATA_ERROR','WALLET_CREATE_DATA_ERROR','WALLET_CREATE_META_DATA_ERROR'),
        map((action: any) => ({ type: 'DIALOG_SHOW', payload: action.payload })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'DIALOG_SHOW',
                payload: error.message,
            });
            return caught;
        }),
    )

    @Effect()
    DialogWarningShow$ = this.actions$.pipe(
        ofType('DIALOG_SHOW'),

        tap((action: any) => {
            this.dialog.open(DialogComponent, {
                disableClose: true,
                autoFocus: false,
                //width : '400px',
            });
        }),

        map(() => ({ type: 'DIALOG_SHOW_SUCCESS' })),
        catchError((error, caught) => {
            console.error(error.message)
            this.store.dispatch({
                type: 'DETAIL_SHOW_ERROR',
                payload: error.message,
            });
            return caught;
        }),
    )

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private dialog: MatDialog,
    ) { }

}